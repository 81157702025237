import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Editac from "../../assets/images/bx_edit.svg";
import Modal from "react-bootstrap/Modal";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import ImagePic from "../../assets/images/createUser.png";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";
import BookIcon from "../../assets/images/ic_excel.svg";
import DelectAcBlc from "../../assets/images/DelectAcBlc.svg";
import Select from "react-select";

import {
  PostCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  DeleteCallWithErrorResponseWithToken,
  simpleGetCallWithErrorResponse,
  simpleGetCallWithToken,
  simplePostCall,
  putMultipartWithAuthCall,
} from "../../api/ApiServices";
import ApiConfig, { BASE_URL } from "../../api/ApiConfig";
import { useStateManager } from "react-select";
import Loader from "../Loader";
import { ToastMsg } from "../TostMsg";
import Beatloader from "../../sharedComponent/Beatloader";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import CreateTeam from "./CreateTeam";

const ManageTeam = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [teamHeadList, setTeamHeadList] = useState([]);
  const [teamMembersList, setTeamMembersList] = useState([]);
  const [teamId, setTeamId] = useState();
  const [EmployeeId, setEmployeeId] = useState([]);
  const [createTeam, setCreateTeam] = useState({
    TeamId: teamId,
    TeamName: "",
    TeamHead: "",
    DepartmentId: "",
    TeamMembersList: "",
    PhotoPath: "",
  });
  console.log(createTeam, "createTeam");
  const [modelLoader, setModelLoader] = useState(false);
  const [error, setError] = useState({
    TeamId: "",
    TeamName: "",
    TeamHead: "",
    TeamMembersList: "",
    DepartmentId: "",
    PhotoPath: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);
  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);
  const [deleteTeamName, setDeleteName] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [membersList, setMemberList] = useState([])
  const [teamHead, setTeamHead] = useState([]);
  //Confirmation popup
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [useDeleteTaskList, setDeleteTaskList] = useState("");

  const [errMsg, setErrMsg] = useState({ EmployeeId: "" });
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    getTeamListApi();
  }, []);

  const [excelLoading, setExcelLoading] = useState(false);
  const [departmentList, setDemaprtmentList] = useState([])


  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    simpleGetCallWithErrorResponse(ApiConfig.MANAGE_TEAM_DOWNLOAD_EXCEL)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (Id) => {
    let payload = {
      TeamId: Id,
    };
    DeleteCallWithErrorResponseWithToken(ApiConfig.DELETE_TEAMLIST + Id).then(
      (res) => {
        if (res) {
          ToastMsg("success", res.json.Message);
          getTeamListApi();
          setLoading(false);
        }
      }
    );
  };

  const getTeamListApi = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_TEAM_LIST)
      .then((res) => {
        setLoading(false);

        setTeamList(res.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTeamDetailsApi = (id) => {
    setModelLoader(true);
    simpleGetCallWithErrorResponse(
      BASE_URL + `api/teampreference/teamdetails?teamId=${id}`
    )
      .then((res) => {
        // console.log(res);
        setModelLoader(false);
        getTeamHeadListApi();
        setCreateTeam({
          TeamId: res.json.Data.TeamId,
          TeamName: res.json.Data.TeamName,
          TeamHead: res.json.Data.TeamHead,
          PhotoPath: res.json.Data.TeamPhoto,
          DepartmentId: res.json.Data.DepartmentId,

        });

        const selectedMembers = res.json.Data.TeamMembersList.map((member) => ({
          label: member.FullName,
          value: member.EmployeeId,
        }));
        setSelectedOptions(selectedMembers);
        setTeamMembersList(res.json.Data.TeamMembersList);
        console.log("imploy ids--->", res.json.Data.TeamMembersList);
        //  const EmployeeList(res.json.Data.EmployeeList)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const selectedMembers = selectedOptions.map((member) => member.value);

    setEmployeeId(selectedMembers);
    console.log("selected Add member--->", EmployeeId);
  }, [selectedOptions]);

  const AddTeamMemberApi = () => {
    if (EmployeeId === undefined) {
      setErrMsg({
        EmployeeId: "Please Select Member. ",
      });
      return;
    } else {
      const body = {
        TeamId: teamId,
        EmployeeId: EmployeeId,
      };

      PostCallWithErrorResponse(
        BASE_URL + `api/teampreference/addmember`,
        JSON.stringify(body)
      )
        .then((res) => {
          if (res.json.Success === true) {
            ToastMsg("success", res.json.Message);

            const addInList = [];

            selectedOptions.map((i) => {
              let indx = teamMembersList.findIndex((mem) => {
                return mem.EmployeeId === i.value;
              });

              if (indx === -1) {
                addInList.push({
                  EmployeeId: i.value,
                  FullName: i.label,
                  Selected: false,
                  EmployeePhoto: null,
                });
              }
            });
            setTeamMembersList([...teamMembersList, ...addInList]);
          } else {
            console.log("error response,", res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getTeamHeadListApi = () => {
    simpleGetCallWithToken(ApiConfig.ACTIVE_EMPLOYEE_LIST)
      .then((res) => {
        if (res?.Success) {
          setLoading(false);
          setTeamHeadList(res?.Data);
          let options = res?.Data.map((name) => ({
            label: name.EmployeeName,
            value: name.EmployeeId,
          }));

          setMemberList(options)

        }
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    simpleGetCallWithToken(ApiConfig.GET_DEPARMENT_LIST)
      .then((res) => {
        console.log(res);
        setDemaprtmentList(res?.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  const UpdateTeamApi = (e) => {
    setLoading(true);
    if (createTeam.DepartmentId === "") {
      setError({
        ...error,
        DepartmentId: "Please enter DepartmentId",
      });
      return;
    }
    if (createTeam.TeamName === "") {
      setError({
        ...error,
        TeamName: "Please enter TeamName",
      });
      return;
    }
    if (createTeam.TeamHead === "") {
      setError({
        ...error,
        TeamHead: "Please Select TeamHead",
      });
      return;
    }
    if (createTeam.TeamHead === "") {
      setError({
        ...error,
        TeamHead: "Please Select TeamHead",
      });
      return;
    }
    if (createTeam.PhotoPath === "") {
      setError({
        ...error,
        PhotoPath: "Please Upload Team Photo",
      });
      return;
    } else {
      let fromdata = new FormData();

      console.log(createTeam.PhotoPath);

      fromdata.append("TeamName", createTeam.TeamName);
      fromdata.append("TeamHead", createTeam.TeamHead);
      fromdata.append("TeamId", createTeam.TeamId);
      fromdata.append("DepartmentId", createTeam.DepartmentId);
      fromdata.append("TeamMembersList", JSON.stringify(teamMembersList));
      fromdata.append("TeamPhoto", createTeam.PhotoPath);
      ExcelLoader(true);
      putMultipartWithAuthCall(ApiConfig.UPDATE_TEAM, fromdata)
        .then((res) => {
          //   setBtnLoader(false);
          if (res.Success === true) {
            setLoading(false);
            ToastMsg("success", res.Message);
            setEdit(false);
            getTeamListApi();
            setErrMsg({
              ...errMsg,
              EmployeeId: "",
            });
          } else {
            console.log("error response,", res);
            ToastMsg("error", res.Message);
            setLoading(false);
          }
        })
        .catch((err) => {
          //   setBtnLoader(false);
          console.log("error response,", err);
          setLoading(false);
        });
    }
  };



  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  const [memberOption, setMmberOption] = useState({
    value: "",
    label: "",
  });
  const handleChangeSElect = (event) => {
    const { value, textContent } = event.target.selectedOptions[0];
    setMmberOption({ value, label: textContent });
    setSelectedOptions((prevOptions) => [
      ...prevOptions,
      {
        EmployeeId: value,
        FullName: textContent,
        Selected: false,
        EmployeePhoto: null,
      },
    ]);
  };

  const handleCheckboxChange = (event, team) => {
    const { checked } = event.target;
    if (checked) {
      setTeamMembersList((prevOptions) => {
        const updatedOptions = prevOptions.map((option) =>
          option.FullName === team.FullName
            ? { ...option, Selected: true }
            : option
        );

        return updatedOptions;
      });
    } else {
      setTeamMembersList((prevOptions) => {
        const updatedOptions = prevOptions.map((option) =>
          option.FullName === team.FullName
            ? { ...option, Selected: false }
            : option
        );

        return updatedOptions;
      });
    }
  };

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    teamList.length &&
    teamList.filter((item) =>
      item.TeamName?.toLowerCase().includes(searchValue)
    );

  const displayTaskList =
    teamList &&
    teamList.slice(startIndex, endIndex).map((team, index) => {
      return (
        <tr className="table-row-custom">
          <td>{startIndex + index + 1}</td>
          <td>
            <img
              style={{ width: "30px", height: "30px" }}
              onError={() => {
                team.TeamPhoto = "swift.png";
                //team.TeamPhoto = ImagePic
                setIsLoading(!isloading);
              }}
              src={
                BASE_URL + "assets/uploads/" + team.TeamPhoto
                // !team.TeamPhoto  // ? ImagePic : BASE_URL+'assets/uploads/'+team.TeamPhoto
                // team.TeamPhoto  ? `${BASE_URL+'assets/uploads/'+team.TeamPhoto}` :

                // icon-user-default.png
                //assets/images/createUser.png

                // !team.TeamPhoto
                //   ? ImagePic
                //   : team.TeamPhoto
                //   ? ApiConfig.BASE_URL_FOR_IMAGES
                //   : team.TeamPhoto && URL.createObjectURL(team.TeamPhoto)
                // ? member_img
                // // : team.PhotoPath.length
                //   ? ApiConfig.BASE_URL_FOR_IMAGES + team.PhotoPath
                //   : team.PhotoPath &&
                //   URL.createObjectURL(team.PhotoPath)
              }
              alt=""
            />
            {console.log("Table small img ----><><>>", ImagePic)}
          </td>
          <td
          // onClick={()=>{
          //   team.TeamPhoto = 'swift.png'
          //   console.log("team data ---------",  team)
          //   console.log("team List data ----------",  teamList)
          //   setTeamList(teamList)
          //   setIsLoading(!isloading)
          // }}
          >
            {team.TeamName}
          </td>
          <td>{team.TeamHeadName}</td>

          <td>
            <img
              onClick={() => {
                setEdit(true);
                getTeamDetailsApi(team.Id);
                setTeamId(team.Id);
              }}
              src={Editac}
              className="mx-2 editBtn"
              alt=""
            />

            <img
              onClick={() => {
                handleShow3();
                // getTeamDetailsApi(team.Id);
                setDeleteTaskList(team.Id);
                setDeleteName(team?.TeamName);
              }}
              src={DelectAcBlc}
              className="mx-2 editBtn"
              alt=""
            />
          </td>
        </tr>
      );
    });

  const displaySearchedTaskList =
    teamList &&
    teamList
      ?.filter((team) =>
        team.TeamName?.toLowerCase().includes(searchValue.toLowerCase())
      )
      .slice(startIndex, endIndex)
      .map((team, index) => {
        return (
          <tr className="table-row-custom">
            <td>{startIndex + index + 1}</td>
            <td>
              <img
                style={{ width: "30px", height: "30px" }}
                src={
                  BASE_URL + "assets/uploads/" + team.TeamPhoto
                  // !team.TeamPhoto
                  //   ? ImagePic
                  //   : team.TeamPhoto
                  //   ? ApiConfig.BASE_URL_FOR_IMAGES+team.TeamPhoto
                  //   : team.TeamPhoto && URL.createObjectURL(team.TeamPhoto)
                }
                alt=""
              />
            </td>
            <td>{team.TeamName}</td>
            <td>{team.TeamHeadName}</td>

            <td>
              <img
                src={Editac}
                className="mx-2 editBtn"
                alt=""
                onClick={() => {
                  setEdit(true);
                  getTeamDetailsApi(team.Id);
                  setTeamId(team.Id);
                }}
              />
              <img
                onClick={() => {
                  handleShow3();
                  // getTeamDetailsApi(team.Id);
                  setDeleteTaskList(team.Id);
                  setDeleteName(team?.TeamName);
                }}
                src={DelectAcBlc}
                className="mx-2 editBtn"
                alt=""
              />
            </td>
          </tr>
        );
      });

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Manage Teams</h6>
          </div>
          <div className="d-flex justify-content-between mainHeader custom-mb">
            <div className="role-table-header-main">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search Team"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25" selected>
                    25
                  </option>
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-4 mainIcons">
              <div className="booksIcon">
                {excelLoading ? (
                  <ExcelLoader />
                ) : (
                  <img
                    src={BookIcon}
                    onClick={(e) => downloadExcelFile(e)}
                    className="innerIconsBtn"
                    alt=""
                  />
                )}
              </div>
            </div>
            {/* <div className="role-btns-wrapper ">
              <button>
                {excelLoading ? (
                  <ExcelLoader />
                ) : (
                  <img
                    src={ic_excel}
                    onClick={(e) => downloadExcelFile(e)}
                    alt=""
                  />
                )}
              </button>
            </div> */}
          </div>
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            {loading ? (
              <Loader />
            ) : teamList && teamList.length > 0 ? (
              <table>
                <tr className="firstTabelRow">
                  <th>Sr.no</th>
                  <th>Image</th>
                  <th>Team Name</th>
                  <th>Team head</th>
                  <th>Action</th>
                </tr>

                <tbody>
                  {searchValue === ""
                    ? displayTaskList
                    : displaySearchedTaskList}
                </tbody>
              </table>
            ) : (
              <>
                <NoData />
              </>
            )}

            {!loading && searchedDataPageCounts.length > usersPerPage && (
              <Pagination
                data={teamList}
                pageChangeHandler={pageChangeHandler}
                usersPerPage={usersPerPage}
                currentPage={currentPage}
                searchValue={searchValue}
                searchedDataPageCounts={searchedDataPageCounts}
              />
            )}
          </div>
        </div>
      </div>

      {/* Edit Modal Task */}

      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={edit}
        onHide={() => setEdit(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Edit Team Details
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img
              src={Axe}
              alt=""
              onClick={() => {
                setErrMsg({
                  ...errMsg,
                  EmployeeId: "",
                });

                setEdit(false);
              }}
            />
          </div>
          {/* <img src={close} alt="" onClick={handleClose} className="cr-class" /> */}
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        {modelLoader ? (
          <Beatloader />
        ) : (
          <Modal.Body>
            <div className="createCard">
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <div className="mb-4">
                    <label htmlFor="" className="enter-role-text">
                      Select Department
                    </label>
                    <select
                      onChange={(e) => {
                        setCreateTeam({
                          ...createTeam,
                          DepartmentId: e.target.value,
                        });
                        // setError({
                        //   ...error,
                        //   DepartmentId: "",
                        // });
                      }}
                      value={createTeam?.DepartmentId}

                      class="form-select tasKCategory1 select-drop-icon"
                      aria-label="Default select example"
                    >
                      <option value="">Select Department</option>
                      {departmentList.map((name, index) => {
                        return (
                          <option value={name.DepartmentId} key={index}>
                            {name.DepartmentName}
                          </option>
                        );
                      })}
                    </select>
                    {error.DepartmentId.length > 0 && !createTeam?.DepartmentId && (
                      <div className="NotFoundLeft">{error.DepartmentId}</div>
                    )}
                  </div>
                  <div className="modalProp mb-4">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "12.8px" }}
                    >
                      Team Name
                    </label>
                    <input
                      onChange={(e) => {
                        setCreateTeam({
                          ...createTeam,
                          TeamName: e.target.value,
                        });
                        setError({
                          ...error,
                          TeamName: "",
                        });
                      }}
                      value={createTeam.TeamName}
                      type="text"
                      placeholder="Team Name"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "15px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    />
                    {error.TeamName.length > 0 && (
                      <div className="NotFoundLeft">{error.TeamName}</div>
                    )}
                  </div>
                  <div className="mb-4">
                    <label htmlFor="" className="enter-role-text">
                      Team Head
                    </label>

                    <select
                      onChange={(e) => {
                        setCreateTeam({
                          ...createTeam,
                          TeamHead: e.target.value,
                        });
                        setError({
                          ...error,
                          TeamHead: "",
                        });
                      }}
                      class="form-select tasKCategory1 select-drop-icon"
                      aria-label="Default select example"
                      style={{
                        fontSize: "15px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    >
                      {/* {teamHeadList
                        .filter((head) => head.Id === createTeam.TeamHead)
                        .map((head) => (
                          <option key={head.Id} value={head.Id}>
                            {head.FullName}
                          </option>
                        ))}
                      {teamHeadList.map((head) => (
                        <option key={head.Id} value={head.Id}>
                          {head.FullName}
                        </option>
                      ))} */}
                      {teamHeadList.map((name, index) => {
                        return (
                          <option value={name.Id} key={index}>
                            {name.EmployeeName}
                          </option>
                        );
                      })}
                    </select>
                    {error.TeamHead.length > 0 && (
                      <div className="NotFoundLeft">{error.TeamHead}</div>
                    )}
                  </div>

                  <div className="mb-4">
                    <label htmlFor="" className="enter-role-text">
                      Add Member
                    </label>

                    <Select
                      class="form-select tasKCategory1 select-drop-icon"
                      options={membersList}

                      onChange={handleChange}
                      isMulti
                      onClick={(e) => {
                        setEmployeeId(e.target.value);
                        setErrMsg({
                          ...errMsg,
                          EmployeeId: "",
                        });
                      }}
                      style={{
                        control: (base) => ({
                          ...base,
                          fontSize: "15px",
                          height: "38px",
                          border: "1px solid #ced4da",
                          borderRadius: "50px", // Adjust the border-radius value here
                        }),
                      }}
                    />
                    {/* <select
                      onChange={handleChangeSElect}
                      class="form-select tasKCategory1 select-drop-icon"
                      aria-label="Default select example"
                      onClick={(e) => {
                        setEmployeeId(e.target.value);
                        setErrMsg({
                          ...errMsg,
                          EmployeeId: "",
                        });
                      }}
                      style={{
                        fontSize: "15px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    >
                      <option value="">Select Team Member</option>

                      {teamHeadList.map((name, index) => {
                        return (
                          <option value={name.Id} key={index}>
                            {name.FullName}
                          </option>
                        );
                      })}
                    </select> */}
                    {errMsg && errMsg.EmployeeId.length > 0 && (
                      <div className="NotFoundLeft">
                        <span> {errMsg.EmployeeId}</span>
                      </div>
                    )}
                  </div>

                  <div className="modalProp mb-4">
                    <label for="formFile" class="enter-role-text mt-2">
                      Upload Photo
                    </label>
                    <input
                      class="form-control tasKCategory1"
                      type="file"
                      accept="image/*"
                      id="formFile"
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];
                        // Check if a file is selected
                        if (selectedFile) {
                          console.log("Selected file size:", selectedFile.size);
                          // Check if the file size exceeds 4MB
                          if (selectedFile.size > 4 * 1024 * 1024) {
                            console.log("File size exceeds 4MB");
                            setError({
                              ...error,
                              PhotoPath: "File size should not exceed 4MB.",
                            });
                          } else {
                            // Get the file extension
                            const fileExtension = selectedFile.name
                              .split(".")
                              .pop()
                              .toLowerCase();
                            // Define allowed extensions
                            const allowedExtensions = ["png", "jpg", "jpeg"];
                            // Check if the file extension is allowed
                            if (!allowedExtensions.includes(fileExtension)) {
                              setError({
                                ...error,
                                PhotoPath:
                                  "Only png, jpg, and jpeg files are allowed.",
                              });
                            } else {
                              setCreateTeam({
                                ...createTeam,
                                PhotoPath: selectedFile,
                              });
                              setError({
                                ...error,
                                PhotoPath: "", // Clear any previous error message
                              });
                            }
                          }
                        } else {
                          // Handle case when no file is selected
                          setError({
                            ...error,
                            PhotoPath: "Please select a file.",
                          });
                        }
                      }}
                      required={!createTeam.PhotoPath ? true : false}
                      style={{
                        fontSize: "15px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    />

                    {error.PhotoPath && (
                      <div className="NotFoundLeft">{error.PhotoPath}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 text-center">
                  <div className="imageHolder">
                    <img
                      className="imgInside2"
                      alt=""
                      onError={() => {
                        createTeam.PhotoPath = "swift.png";
                        //team.TeamPhoto = ImagePic

                        setIsLoading(!isloading);
                      }}
                      src={
                        //BASE_URL+'assets/uploads/'+createTeam.PhotoPath
                        //BASE_URL+'assets/images/createUser.png'
                        //createTeam.PhotoPath ? BASE_URL+'assets/images/createUser.png' :createTeam.PhotoPath && URL.createObjectURL(createTeam.PhotoPath)
                        // &&
                        !createTeam.PhotoPath
                          ? ImagePic
                          : createTeam.PhotoPath.length
                            ? BASE_URL + "assets/uploads/" + createTeam.PhotoPath
                            : createTeam.PhotoPath &&
                            URL.createObjectURL(createTeam.PhotoPath)
                        //createTeam.PhotoPath.length == 0 ? createTeam.PhotoPath : ImagePic
                      }
                    />
                  </div>
                  {console.log("Photo 1111111>>>>>>>", createTeam.PhotoPath)}
                  <h4 className="create-role-text headingCusto">
                    Select Employees To Remove From Team.
                  </h4>
                  <div className="removeTable table-wrapper">
                    <table style={{ padding: "10" }}>
                      <thead>
                        <tr>
                          <th>Sr.no</th>
                          <th>Name</th>
                          <th>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {teamMembersList &&
                          teamMembersList.map((team, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                  <label class="form-check-label" for="Name1">
                                    {team.FullName}
                                  </label>
                                </td>
                                <td>
                                  <input
                                    // checked={team.Selected}
                                    // value={team.FullName}
                                    onChange={(event) =>
                                      handleCheckboxChange(event, team)
                                    }
                                    class="form-check-input"
                                    type="checkbox"
                                    id="Name1"
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col">
                  <button
                    className="modal-create-btn"
                    onClick={AddTeamMemberApi}
                  >
                    Add Member
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <button
            className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
            onClick={() => setEdit(false)}
          >
            Cancel
          </button>
          <button
            className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
            onClick={UpdateTeamApi}
          >
            Update Team
          </button>
        </Modal.Footer>
      </Modal>
      {/* Confirmation popup Announcement */}

      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure, you want to delete {deleteTeamName}? </p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>

          <button
            className="btn-width saveBtn"
            onClick={() => {
              handleDelete(useDeleteTaskList);
              // handleDelete2()
              handleClose3();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
      {isloading && <></>}
    </>
  );
};

export default ManageTeam;
